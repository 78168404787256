import React, { createContext, useState, ReactNode, useMemo } from 'react';

import { garmRiskOptions, sentimentOptions } from '../Helpers/FilterOptions';
import { labelToValueMap } from 'Helpers/utils';

export interface Filter {
  label: string;
  value: string;
}

export type FilterKey = 'emotion' | 'garmRisk' | 'genre' | 'sentiment' | 'theme' | 'tone';

interface FiltersContextType {
  filters: Record<FilterKey, Filter[]>;
  filterDropdowns: FilterOption[];
  lastPage: string;
  clearFilters: () => void;
  initialize: (statistics: Record<FilterKey, [string, number][]>) => void;
  onFilterInputChange: (filterKey: FilterKey) => (keyword: string) => void;
  setFilterByLabel: (filterKey: FilterKey, label: string) => void;
  setAllDropdownOptions: React.Dispatch<React.SetStateAction<Record<FilterKey, Filter[]>>>;
  setDynamicDropdownOptions: React.Dispatch<React.SetStateAction<Record<FilterKey, Filter[]>>>;
  setFilters: React.Dispatch<React.SetStateAction<Record<FilterKey, Filter[]>>>;
  setLastPage: React.Dispatch<React.SetStateAction<string>>;
  setRedirection: (fromPage: string | undefined) => void;
}

const initialValues = {
  emotion: [],
  garmRisk: [],
  genre: [],
  sentiment: [],
  theme: [],
  tone: [],
}

const initialOptions: Record<FilterKey, Filter[]> = {
  garmRisk: garmRiskOptions,
  sentiment: sentimentOptions,
  ...initialValues,
}

const initialFilters: Record<FilterKey, Filter[]> = {
  ...initialValues,
}

const AUTOCOMPLETE_FILTER_KEYS = ['theme', 'tone', 'genre', 'emotion'];

const TOP_COUNT = 100;

const DEFAULT_FILTERS = {
  filters: initialFilters,
  filterDropdowns: [],
  lastPage: '/',
  clearFilters: () => {},
  initialize: () => {},
  onFilterInputChange: () => (keyword: string) => {},
  setAllDropdownOptions: () => {},
  setDynamicDropdownOptions: () => {},
  setFilterByLabel: () => {},
  setFilters: () => {},
  setLastPage: () => {},
  setRedirection: () => {},
};

export const FiltersContext = createContext<FiltersContextType>(DEFAULT_FILTERS);

export type FilterOption = {
  id: FilterKey;
  displaySetter?: ((value: Filter[]) => void);
  options: Filter[];
  setter: (value: Filter[]) => void;
  title: string;
  type?: 'autocomplete' | 'dropdown';
  values: Filter[];
};
interface FiltersProviderProps {
  children: ReactNode;
}

const asc = (a, b) => a.localeCompare(b);
const byCountDesc = (a, b) => b[1] - a[1];
const filterObjectToArray = ({ labels, data }) => labels.map((label, index) => ([label, data[index]]));

export const FiltersProvider: React.FC<FiltersProviderProps> = ({ children }) => {
  const [lastPage, setLastPage] = useState<string>('/');
  const [filters, setFilters] = useState<Record<FilterKey, Filter[]>>(initialFilters);
  const [filtersDisplay, setFiltersDisplay] = useState<Record<FilterKey, Filter[]>>(initialFilters);

  // List of all available dropdown options
  const [allDropdownOptions, setAllDropdownOptions] = useState<Record<FilterKey, Filter[]>>(initialOptions);

  // List of dropdown options that are currently displayed
  const [dynamicDropdownOptions, setDynamicDropdownOptions] = useState<Record<FilterKey, Filter[]>>(initialOptions);

  // List of dropdown options that are initially displayed
  const [initialDropdownOptions, setInitialDropdownOptions] = useState<Record<FilterKey, Filter[]>>(initialOptions);

  const initialize = (statistics: Record<FilterKey, [string, number][]>) => {
    AUTOCOMPLETE_FILTER_KEYS.forEach((key) => {
      if (!statistics[key]) return;
      const { labels, data } = statistics[key];
      const allOptions = labels.map(labelToValueMap);
      const initialOptions = filterObjectToArray({ labels, data })
        .sort(byCountDesc)
        .slice(0, TOP_COUNT)
        .map((o) => o[0])
        .sort(asc)
        .map(labelToValueMap);
      setAllDropdownOptions((current) => ({ ...current, [key]: allOptions }));
      setDynamicDropdownOptions((current) => ({ ...current, [key]: initialOptions }));
      setInitialDropdownOptions((current) => ({ ...current, [key]: initialOptions }));
    });
  }

  const makeSetter = (filterKey: FilterKey) => (filters: Filter[]) => setFilters((current) => ({ ...current, [filterKey]: filters }));

  const setFilterByLabel = (filterKey: FilterKey, label: string) => {
    const dropdownOption = filterDropdowns.find((dropdown) => dropdown.id === filterKey);
    if (!dropdownOption) return;
    const filter = dropdownOption.options.find((option) => option.label === label);
    if (!filter) return;
    dropdownOption.setter([filter]);
    dropdownOption.displaySetter?.([filter]);
  };

  const clearFilters = () => {
    setFilters(initialFilters);
    setFiltersDisplay(initialFilters);
  };

  const setRedirection = (fromPage: string | undefined): void => setLastPage(fromPage || '/');

  const getFilteredOptions = (filterKey: FilterKey) => (keyword: string) =>
    allDropdownOptions[filterKey].filter((option) => option.label.toLowerCase().includes(keyword.toLowerCase()));

  const onFilterInputChange = (filterKey: FilterKey) => (keyword: string) => {
    if (!keyword) {
      return setDynamicDropdownOptions((current) => ({ ...current, [filterKey]: initialDropdownOptions[filterKey] }));
    };
    if (keyword.length < 3) {
      return setDynamicDropdownOptions((current) => ({ ...current, [filterKey]: [] }));
    };
    const filteredOptions = getFilteredOptions(filterKey)(keyword);
    setDynamicDropdownOptions((current) => ({ ...current, [filterKey]: filteredOptions }));
  }

  const filterDropdowns: FilterOption[] = useMemo(() => [
    {
      id: 'garmRisk',
      title: 'Risk',
      options: garmRiskOptions,
      values: filtersDisplay.garmRisk,
      displaySetter: (value: Filter[]) => setFiltersDisplay((current) => ({ ...current, garmRisk: value })),
      setter: makeSetter('garmRisk'),
    },
    {
      id: 'sentiment',
      title: 'Sentiment',
      options: sentimentOptions,
      values: filtersDisplay.sentiment,
      displaySetter: (value: Filter[]) => setFiltersDisplay((current) => ({ ...current, sentiment: value })),
      setter: makeSetter('sentiment'),
    },
    {
      id: 'emotion',
      title: 'Emotion',
      options: dynamicDropdownOptions.emotion,
      values: filters.emotion,
      setter: makeSetter('emotion'),
      type: 'autocomplete',
    },
    {
      id: 'genre',
      title: 'Genre',
      options: dynamicDropdownOptions.genre,
      values: filters.genre,
      setter: makeSetter('genre'),
      type: 'autocomplete',
    },
    {
      id: 'theme',
      title: 'Theme',
      options: dynamicDropdownOptions.theme,
      values: filters.theme,
      setter: makeSetter('theme'),
      type: 'autocomplete',
    },
    {
      id: 'tone',
      title: 'Tone',
      options: dynamicDropdownOptions.tone,
      values: filters.tone,
      setter: makeSetter('tone'),
      type: 'autocomplete',
    },
  ], [filters, filtersDisplay, dynamicDropdownOptions]);

  return (
    <FiltersContext.Provider value={{
      filters,
      filterDropdowns,
      lastPage,
      clearFilters,
      initialize,
      onFilterInputChange,
      setAllDropdownOptions,
      setDynamicDropdownOptions,
      setFilterByLabel,
      setFilters,
      setLastPage,
      setRedirection,
    }}>
      {children}
    </FiltersContext.Provider>
  );
};
