import React, { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from '@emotion/styled';

interface Props {
  loadingPercentage: number;
  loadingLabel?: string;
  margin?: string;
  minimumPercentage?: number;
  steps?: number[];
  stepsInterval?: number;
}

const LoadingBar: React.FC<Props> = ({ loadingPercentage, loadingLabel, margin, minimumPercentage = 0, steps = [], stepsInterval = 60000 }) => {
  const [hideLoadingBar, setHideLoadingBar] = useState(false);
  const [dynamicLoadingPercentage, setDynamicLoadingPercentage] = useState(loadingPercentage);

  const nextStep = useMemo(() => steps.length ? steps[steps.findIndex(step => step > loadingPercentage)] : 99.9, [loadingPercentage, steps]);
  const stepDifference = nextStep - loadingPercentage;

  function handleHideLoadingBar() {
    setHideLoadingBar(true);
  }

  useEffect(() => {
    setDynamicLoadingPercentage(loadingPercentage < minimumPercentage ? minimumPercentage : loadingPercentage);
  }, [loadingPercentage, minimumPercentage]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    const updateRate = 1000 / 24;
    const step = stepDifference / (stepsInterval / updateRate);
    const callback = (prev) => {
      const nextPercentage = prev + step;
      if (nextPercentage < nextStep) return nextPercentage;
      clearInterval(interval);
      return nextStep;
    };
    interval = setInterval(() => {
      setDynamicLoadingPercentage(callback);
    }, updateRate);
    return () => clearInterval(interval);
  }, [nextStep, loadingPercentage, stepDifference, steps, stepsInterval]);

  return (
    <ProgressItems margin={margin} sx={{ visibility: hideLoadingBar ? 'hidden' : 'visible' }}>
      {dynamicLoadingPercentage >= 0 && dynamicLoadingPercentage < 99.9 && (
        <Box position="relative" width="100%">
          <LinearProgress
            variant="determinate"
            value={dynamicLoadingPercentage}
            sx={{
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: '#4CAF50',
              },
              height: 24,
              backgroundColor: 'white', 
              borderRadius: 5,
              position: 'relative',
            }}
          />
          <Box
            position="absolute"
            top="0"
            left="0"
            paddingLeft="9px"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
          >
            <Typography fontSize="12px" fontWeight="600" sx={{ color: 'white', marginRight: 0.5 }}>
              {loadingLabel}
            </Typography>
          </Box>
        </Box>
      )}
      {loadingPercentage === 99.9 && (
        <Box position="relative" width="100%" sx={{ color: 'lightgreen' }}>
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: '#4CAF50',
              },
              height: 24,
              borderRadius: 5,
              position: 'relative',
            }}
          />
          <Box
            position="absolute"
            top="0"
            left="0"
            paddingLeft="9px"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
          >
            <Typography fontSize="12px" fontWeight="600" sx={{ color: 'white', marginRight: 0.5 }}>
              READY
            </Typography>
          </Box>
          <Box
            position="absolute"
            top="0"
            right="0"
            paddingRight="6px"
            height="100%"
            display="flex"
            alignItems="center"
            onClick={handleHideLoadingBar}
          >
            <CheckCircleIcon sx={{ color: 'white', fontSize: 16 }} />
          </Box>
        </Box>
      )}
    </ProgressItems>
  );
};

export default LoadingBar;

const ProgressItems = styled(Box)<{ margin?: string }>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.margin || 'auto'};
  padding-top: 10px;
  position: relative;
  width: 100%;
`;
