import React, { useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import api from 'api';
import styled from '@emotion/styled';

import { UrlContext } from 'context';
import AdditionalContext from '../AdditionalContext';
import ContentEmbed from '../ContentEmbed';
import LoadingBar from '../LoadingBar';
import RiskGauge from '../RiskGauge';
import SentimentIndicator from '../SentimentIndicator';
import MetadataTagDisplay from '../MetadataTagDisplay';
import MetadataSummary from '../MetadataSummary';
import useVideoInfo from './useVideoInfo';
import { isMetadataReady, fetchLabels, getVideoTitle, fetchPersonaDetail, ensureHttpsInUrl } from './utils';
import Env from 'Helpers/environment';
import 'App.css';
import './VideoInfo.css';

const NEVER_METADATA_SOURCE_TYPES = ['cloud_file_storage'];
const WAITING_STATUSES = [2, 0, undefined];
const NO_CONTENT = -1;
const BACKLOGGED_STATUS = 3;
const maximumProcessingPercentage = 100;
const STEPS = [0, 33, 66, 95];
const TIME_BETWEEN_STEPS = 1000 * 60;

export default function VideoInfo() {
  const { setUrl } = useContext(UrlContext);
  const { videoUrl } = useParams();
  const location = useLocation();
  const timeoutRefs = useRef([]);
  const navigate = useNavigate();
  const fromPage = location.state?.from ?? '/';
  const isPersonaEnabled = Env.PersonaEnvs.includes(Env.CurrentEnv);

  const { state, setState, setIfNotUndefined, setStateIfTruthy } = useVideoInfo();
  const {
    alertMessage,
    emotionData,
    emotionDataReady,
    gptRiskDescription,
    gptSentiment,
    gptSummary,
    highlights,
    isLoading,
    loadingLabel,
    loadingPercentage,
    metadata,
    metadataReady,
    personas,
    riskLabel,
    riskLabelMap,
    sentimentLabelMap,
    showAlert,
    showProgressBar,
    source,
    timeoutId,
    topicData,
    topicDataReady,
    videoId,
    videoTitle,
  } = state;

  const isCollectingData = (responseBody) => {
    return WAITING_STATUSES.includes(responseBody.text_collect_status) ||
      (WAITING_STATUSES.includes(responseBody.image_collect_status) && loadingLabel !== 'Collecting data...');
  };

  const fetchInitialData = async () => {
    const [riskLabelMap, sentimentLabelMap] = await Promise.all([fetchLabels('risk-labels'), fetchLabels('sentiment-labels')]);
    setState({ riskLabelMap, sentimentLabelMap });
  };

  const fetchPersonaData = useCallback(async (url) => {
    try {
      const webpageDetailResp = await api.persona.webpageDetail(url);
      const personaIds = webpageDetailResp.body?.persona_ids || [];
      const videoPersonas = await Promise.all(personaIds.map(fetchPersonaDetail)).then(personas => personas.filter(p => p !== null));
      if (videoPersonas.length) setState({ personas: videoPersonas });
    } catch (error) {
      console.error('Failed to load persona webpage detail', error);
    }
  }, [setState]);

  const checkStatus = async (url) => {
    try {
      const infoResponse = await api.mbs.getVideoInfo(url);
      const loadingPercentage = infoResponse.body.loading_percentage !== undefined ? infoResponse.body.loading_percentage * maximumProcessingPercentage : 0;
      const newVideoTitle = getVideoTitle(infoResponse.body);
      setStateIfTruthy({
        source: infoResponse.body.source,
        videoId: infoResponse.body.video_id,
        videoTitle: newVideoTitle
      });
      setIfNotUndefined({
        loadingPercentage,
      });
      setState({
        source: infoResponse.body.source
      });

      if (isMetadataReady(infoResponse.body)) {
        setState({ metadataReady: true });
      }

      setState({ loadingLabel: isCollectingData(infoResponse.body) ? 'Collecting data...' : 'Processing data...' });

      if (!WAITING_STATUSES.includes(infoResponse.body.gpt_complete)) {
        setState({
          riskLabel: infoResponse.body.gpt_garm_level,
          isLoading: false,
          loadingLabel: null
        });
        if (infoResponse.body.gpt_complete === NO_CONTENT || infoResponse.body.gpt_complete === BACKLOGGED_STATUS) {
          setState({
            alertMessage: `Unable to access ${infoResponse.body.content_type} for processing. Redirecting to gallery...`,
            loadingPercentage: 0,
            showAlert: true,
            showProgressBar: false,
            timeoutId: setTimeout(() => {
              setState({
                alertMessage: '',
                showAlert: false
              });
              navigate('/');
            }, 10000)
          });
        } else {
          setState({
            emotionDataReady: true,
            gptRiskDescription: infoResponse.body.gpt_risk_desc,
            gptSummary: infoResponse.body.gpt_summary,
            loadingPercentage: 99.9,
            riskLabel: infoResponse.body.gpt_garm_level,
            topicDataReady: true
          });
          setIfNotUndefined({
            gptSentiment: infoResponse.body.gpt_sentiment,
          });
          setStateIfTruthy({
            highlights: infoResponse.body.highlights
          });
        }
      } else {
        const id = setTimeout(() => checkStatus(url), 8000);
        timeoutRefs.current.push(id);
      }
    } catch (error) {
      console.error('Failed to fetch video info:', error);
    }
  };

  // TODO: Refactor. This function is doing too much, and much more than its name suggests.
  const fetchVideoData = async (url) => {
    setState({
      alertMessage: '',
      source: null,
      emotionData: null,
      emotionDataReady: false,
      gptRiskDescription: null,
      gptSentiment: null,
      gptSummary: null,
      highlights: null,
      loadingPercentage: 0,
      metadata: null,
      metadataReady: false,
      riskLabel: null,
      showAlert: false,
      topicData: null,
      topicDataReady: false,
      videoId: null,
      videoTitle: null
    });

    if (!url) {
      console.log('No valid url!');
      return;
    }

    setState({ isLoading: true, loadingLabel: 'Submitting url for processing...' });

    const addResponse = await api.mbs.addVideo(url);

    if (addResponse.body.gpt_complete === NO_CONTENT || addResponse.body.gpt_complete === BACKLOGGED_STATUS) {
      setState({
        alertMessage: `Unable to access ${addResponse.body.content_type} for processing. Redirecting to gallery...`,
        isLoading: false,
        loadingLabel: null,
        loadingPercentage: 0,
        riskLabel: addResponse.body.gpt_complete,
        showAlert: true,
        showProgressBar: false,
        timeoutId: setTimeout(() => {
          setState({ alertMessage: '', showAlert: false });
          navigate('/');
        }, 10000)
      });
    }

    setStateIfTruthy({
      source: addResponse.body.source,
      videoId: addResponse.body.video_id
    });
    setState({ videoTitle: getVideoTitle(addResponse.body) });

    if (!WAITING_STATUSES.includes(addResponse.body.gpt_complete)) {
      setState({
        source: addResponse.body.source,
        emotionDataReady: true,
        gptRiskDescription: addResponse.body.gpt_risk_desc,
        gptSummary: addResponse.body.gpt_summary,
        isLoading: false,
        loadingLabel: null,
        loadingPercentage: 99.9,
        metadataReady: true,
        topicDataReady: true
      });
      setIfNotUndefined({
        gptSentiment: addResponse.body.gpt_sentiment,
      });
      setStateIfTruthy({
        highlights: addResponse.body.highlights,
        riskLabel: addResponse.body.gpt_garm_level
      });
    } else if (addResponse.status === 200) {
      console.log('Starting polling.');
      if (isMetadataReady(addResponse.body)) {
        setState({ metadataReady: true });
      }

      setState({
        isLoading: true,
        loadingLabel: isCollectingData(addResponse.body) ? 'Collecting data...' : 'Processing data...',
        showProgressBar: true,
      });

      checkStatus(url);
    } else if (addResponse.status === 206 || addResponse.status === 502) {
      setState({
        alertMessage: addResponse.body.message,
        isLoading: false,
        loadingLabel: null,
        loadingPercentage: 0,
        showAlert: true,
        timeoutId: setTimeout(() => {
          setState({ alertMessage: '', showAlert: false });
        }, 10000)
      });
    } else {
      console.log('Add video was not successful:', addResponse.status);
    }
  };

  const handleBackClick = useCallback(() => {
    setUrl('');
    navigate(fromPage, { state: { from: location.pathname } });
  }, [setUrl, navigate, fromPage, location.pathname]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (riskLabel === NO_CONTENT) return;

    if (isPersonaEnabled) {
      fetchPersonaData(videoUrl);
    }

  }, [videoUrl, riskLabel]);

  useEffect(() => {
    window.scrollTo(0, 0); // React router is preserving scroll position for some reason
    const currentTimeoutRefs = timeoutRefs.current;
    return () => {
      currentTimeoutRefs.forEach(clearTimeout);
    };
  }, []);

  useEffect(() => {
    if (!videoUrl) return;
    const decodedUrl = decodeURIComponent(videoUrl);
    setUrl(decodedUrl);

    // If video data is not available in location state, fetch it
    if (!location.state?.content) {
      console.log('No video data in location state, fetching from API...');
      fetchVideoData(decodedUrl);
    } else {
      console.log('Passed risk label:', location.state?.content?.gpt_garm_level);
      setState({
        emotionDataReady: true,
        metadataReady: true,
        topicDataReady: true,
      });
    }
  }, [videoUrl, location.state?.content]);

  useEffect(function fetchTopics() {
    (async () => {
      if (!topicDataReady || !videoId) return;
      try {
        const topicResponse = await api.mbs.getVideoTopic(videoId);
        setState({ topicData: topicResponse.body.topics });
      } catch (error) {
        console.error('Failed to fetch topics:', error);
      }
    })();
  }, [videoId, topicDataReady]);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (!source || NEVER_METADATA_SOURCE_TYPES.includes(source)) return;
      if (!metadataReady || !videoUrl) return;
      try {
        const metadataResponse = await api.mbs.getMetadata(decodeURIComponent(videoUrl));
        if (metadataResponse.status !== 200) {
          console.error('Failed to fetch metadata:', metadataResponse);
        }
        setStateIfTruthy({ source: metadataResponse.body.source });
        setState({ metadata: metadataResponse.body });
      } catch (error) {
        console.error('Failed to fetch metadata:', error);
      }
    }
    fetchMetadata();
  }, [videoUrl, metadataReady]);

  useEffect(() => {
    const fetchEmotion = async () => {
      if (!emotionDataReady || !videoId) return;
      try {
        const emotionResponse = await api.mbs.getVideoEmotion(videoId);
        setState({ emotionData: emotionResponse.body.emotion });
      } catch (error) {
        console.error('Failed to fetch topics:', error);
      }
    };
    fetchEmotion();
  }, [videoId, emotionDataReady]);

  return (
    <Box className="scorecard-background">
      {showAlert && (
        <div className="alert-message">
          {alertMessage}
          <button
            onClick={() => {
              setState({ showAlert: false });
              clearTimeout(timeoutId);
            }}
            className="close-button"
          >
            X
          </button>
        </div>
      )}
      <Box className="safety-info-container">
        <Box width="100%" display="flex" justifyContent="flex-start">
          <button className="back-button" onClick={handleBackClick}>
            ←
          </button>
        </Box>
        {showProgressBar && (
          <LoadingBar
            loadingPercentage={loadingPercentage}
            loadingLabel={loadingLabel}
            minimumPercentage={15}
            steps={STEPS}
            stepsInterval={TIME_BETWEEN_STEPS}
          />
        )}
        {riskLabel !== NO_CONTENT && (
          <Box className="safety-info-container-inner">
            {videoTitle &&
              (source === 'article' ? (
                <a
                  href={ensureHttpsInUrl(videoUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-style-link"
                >
                  <h1 className="video-title">{videoTitle}</h1>
                </a>
              ) : (
                <h1 className="video-title">{videoTitle}</h1>
              ))}
            {source && videoId && <ContentEmbed source={source} embedId={videoId} url={videoUrl} />}
            <Box sx={{ filter: isLoading ? 'grayscale(1)' : 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="stretch"
                  padding="10px 0"
                >
                  <Box
                    width="calc(50% - 4px)"
                    bgcolor="white"
                    borderRadius="6px"
                    boxShadow="0 4px 2px -2px #CFD8DC"
                    display="flex"
                    padding="8px"
                    marginRight="8px"
                  >
                    <RiskGauge riskLevel={riskLabelMap ? riskLabelMap[riskLabel]?.label : ''} />
                  </Box>
                  <Box
                    width="calc(50% - 4px)"
                    bgcolor="white"
                    borderRadius="6px"
                    boxShadow="0 4px 2px -2px #CFD8DC"
                    display="flex"
                    padding="8px"
                  >
                    <SentimentIndicator
                      sentiment={sentimentLabelMap ? sentimentLabelMap[gptSentiment]?.label : ''}
                    />
                  </Box>
                </Box>
                <Box
                  bgcolor="white"
                  borderRadius="6px"
                  boxShadow="0 4px 2px -2px #CFD8DC"
                  padding="16px"
                >
                  <Typography fontSize="16px" fontWeight="600" color="#263228">
                    Risk Summary
                  </Typography>
                  {gptRiskDescription ? (
                    <Typography fontSize="14px" color="#607D8B">
                      {gptRiskDescription}
                    </Typography>
                  ) : (
                    <Box width="100%" display="flex" flexDirection="column">
                      <PlaceholderLine $widthPercent={86} />
                      <PlaceholderLine $widthPercent={90} />
                      <PlaceholderLine $widthPercent={74} />
                    </Box>
                  )}
                  {highlights && source && (
                    <AdditionalContext videoId={videoId} highlights={highlights} source={source} />
                  )}
                </Box>
              </Box>
              <div>
                <MetadataTagDisplay
                  emotionData={emotionData}
                  topics={topicData}
                  keywordData={metadata}
                  personas={personas}
                />
              </div>
              {(metadata || NEVER_METADATA_SOURCE_TYPES.includes(source)) && (
                <Box marginBottom="8px">
                  <MetadataSummary
                    metadata={metadata}
                    summary={gptSummary}
                    topics={topicData}
                    emotionData={emotionData}
                    source={source}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const PlaceholderLine = styled.div<{ $widthPercent: number }>`
  width: ${(props) => props.$widthPercent}%;
  min-width: ${(props) => props.$widthPercent}%;
  margin-bottom: 9px;
  height: 11px;
  background-color: #d9d9d9;
`;
