import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Close } from '@mui/icons-material';
import { FilterOption, FiltersContext } from '../context/FiltersContext';
import { Autocomplete, Chip, Popper, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { isMobile, labelToValue } from 'Helpers/utils';

const FilterDropdownMulti: React.FC<FilterOption> = ({
  id,
  options,
  setter,
  title,
  values,
}) => {
  const [value, setValue] = useState('');
  const { onFilterInputChange } = useContext(FiltersContext);

  const selectedValues = useMemo(() => values.map((v) => v.label), [values]);

  const Tags = (value: string[], getTagProps) => value.map((option: string, index: number) => (
    <StyledChip
      key={option}
      label={option}
      {...getTagProps({ index })}
      deleteIcon={<Close fontSize="small" />}
    />
  ))

  const onInputChange = useCallback((keyword: string) => {
    setValue(keyword);
    onFilterInputChange(id)(keyword);
  }, [id, onFilterInputChange])

  const Input = useCallback((props) => (
    <TextField
      {...props}
      onChange={(e) => onInputChange(e.target.value)}
      placeholder={title}
      size="small"
      sx={{
        backgroundColor: '#fff',
        borderRadius: '4px',
      }}
    />
  ), [onInputChange, title])

  const onChange = useCallback((_, newValue) => {
    setValue('');
    onFilterInputChange(id)('');
    const lastValue = newValue[newValue.length - 1];
    if (!lastValue) {
      setter([]);
      return;
    }
    const valueIsAnOption = options.find((o) => o.value === labelToValue(lastValue));
    if (!valueIsAnOption) return;
    const values = newValue.map((v) => ({ label: v, value: v.toLowerCase() }));
    setter(values);
  }, [id, onFilterInputChange, options, setter])

  const filterOptions = useCallback((options) => {
    const selectedValues = values.map((o) => o.value);
    const removeSelectedFilter = (o) => !selectedValues.includes(o.toLowerCase());
    const matchesValueFilter = (o) => o.toLowerCase().includes(value.toLowerCase());
    const filteredOptions = options.filter(removeSelectedFilter).filter(matchesValueFilter);
    return filteredOptions;
  }, [values, value])

  return (
    <StyledAutocomplete
      freeSolo={isMobile()}
      filterOptions={filterOptions}
      key={title}
      multiple
      onChange={onChange}
      options={options.map((option) => option.label)}
      PopperComponent={(props) => <Popper {...props} sx={{ width: 'auto !important' }} />}
      renderInput={Input}
      renderTags={Tags}
      value={selectedValues}
    />
  );
};

export default FilterDropdownMulti;

const StyledChip = styled(Chip)`
  background-color: #263238;
  color: white;
  border-radius: 8px;
  margin: 1px;
  padding: 0px 0px;
  max-width: 100%;

  & .MuiChip-deleteIcon {
    color: white;
    &:hover {
      color: #ccc;
    }
  }

  & .MuiChip-label {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (max-width: 600px) {
    font-size: 8px;
    & .MuiChip-deleteIcon {
      font-size: 14px;
      margin: 0 2px 0 -2px;
    }
    & .MuiChip-label {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ddd !important;
  }
  @media (max-width: 600px) {
    & .MuiAutocomplete-input {
      font-size: 12px;
    }
  }
  @media (max-width: 400px) {
    & .MuiAutocomplete-input {
      font-size: 8px;
    }
  }
  & .MuiInputBase-input::placeholder {
    color: inherit;
    opacity: 1;
  }
`;
