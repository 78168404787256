import api from "api";

export const isMetadataReady = (responseBody) => {
  return ['youtube', 'tiktok', 'instagram', 'iris_tv'].includes(responseBody.source) ||
    (responseBody.source === 'article' && responseBody.article_title) ||
    (responseBody.source === 'xsocial' && responseBody.channel_name) ||
    (responseBody.source === 'omny' && responseBody.podcast_title);
};

export const fetchLabels = async (labelType) => {
  const labelsResponse = await api.mbs.getLabels(labelType);
  const items = labelsResponse.categories || labelsResponse.labels;
  if (!items) return;
  return items.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
};

export const getVideoTitle = (responseBody) => {
  const titleMap = {
    youtube: responseBody.video_title,
    article: responseBody.article_title,
    tiktok: responseBody.video_title,
    xsocial: responseBody.post_title || responseBody.channel_nickname,
    iris_tv: responseBody.video_title,
    cloud_file_storage: responseBody.video_title,
    omny: responseBody.podcast_title
  };
  return titleMap[responseBody.source];
};

export const fetchPersonaDetail = async (personaId) => {
  try {
    const { body: persona } = await api.persona.personaDetail(personaId);
    return persona;
  } catch (error) {
    console.error(`Failed to fetch persona detail for ID ${personaId}:`, error);
    return null;
  }
};

export const ensureHttpsInUrl = (url) => {
  const newUrl = new URL(url);
  newUrl.protocol = 'https:';
  return newUrl.toString();
};
